<template>
  <div id="governorates">
    <div class="container-fluid">
      <!-- Add Edit Section -->
      <div class="add-section" v-if="canAccess(['store-governorate','update-governorate'])">
        <b-modal id="governorates-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')"
                 :ok-title="$t('save')" :title="$t('sidebar.governorates')" @ok="submit">
          <b-form class="d-grid grid-cols-1 gap-5">
            <div class="feild">
              <label for="">{{ $t('ar-name') }}</label>
              <b-form-input
                type="text"
                placeholder=""
                v-model="payload.ar_name"
                required
              ></b-form-input>
            </div>
            <div class="feild">
              <label for="">{{ $t('en-name') }}</label>
              <b-form-input
                type="text"
                placeholder=""
                v-model="payload.en_name"
                required
              ></b-form-input>
            </div>
            <div class="feild">
              <label for="">{{ $t('kr-name') }}</label>
              <b-form-input
                type="text"
                placeholder=""
                v-model="payload.kr_name"
                required
              ></b-form-input>
            </div>
          </b-form>
        </b-modal>
      </div>
      <!-- Filters Section -->
      <div class="filters-section">
        <iq-card class="w-100">
          <template v-slot:body>
            <div class="filter-container" @change="filtering">
                <b-form-input
                  type="text"
                  :placeholder="$t('filter-name')"
                  v-model="filterData[`${$i18n.locale}_name`]"
                  required
                ></b-form-input>
            </div>
          </template>
        </iq-card>
      </div>
      <!-- Tabel Section -->
      <div class="table-sections">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('governorates-list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button v-if="canAccess(['store-governorate'])" variant="primary" @click="addInit" size="sm">{{
                $t('add')
              }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-table responsive v-if="canAccess(['get-governorate','delete-governorate','update-governorate'])"
                     :items="governorates.data" :fields="fields" class="mb-0 table-borderless">
              <template v-slot:cell(actions)="data">
                <div class="actions-list d-flex align-items-center" style="gap: 5px;">
                  <b-button v-if="canAccess(['update-governorate'])" v-b-tooltip.top="$t('edit')"
                            variant=" iq-bg-success" @click="update(data.item)" size="md"><i
                    class="ri-ball-pen-fill p-0"></i></b-button>
<!--                  <b-button v-if="canAccess(['delete-governorate'])" v-b-tooltip.top="$t('remove')"-->
<!--                            variant=" iq-bg-danger" size="md" @click="remove(data.item.id)"><i-->
<!--                    class="ri-delete-bin-line p-0"></i></b-button>-->
                </div>
              </template>
            </b-table>
          </template>
        </iq-card>
      </div>
      <!-- Pagination Section -->
      <div class="pagination">
        <iq-card class="w-100 d-flex justify-content-center">
          <template v-slot:body>
            <b-pagination
              v-model="filterData.page"
              :total-rows="governorates.total"
              :per-page="governorates.per_page"
            ></b-pagination>
          </template>
        </iq-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'governorates',
  data () {
    return {
      filterData: {
        take: 10,
        page: 1,
        ar_name: '',
        en_name: '',
        kr_name: ''
      },
      payload: {
        ar_name: '',
        en_name: '',
        kr_name: ''
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          label: this.$t('name'),
          key: `${this.$i18n.locale}_name`
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('created-by'),
          key: 'created_by.name'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getGovernorates(this.filterData)
    },
    addInit () {
      this.$bvModal.show('governorates-modal')
    },
    update (data) {
      Object.assign(this.payload, data)
      this.$bvModal.show('governorates-modal')
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeGovernorates(id)
      })
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.payload.id) {
        this.addGovernorates(this.payload).then(() => {
          this.$bvModal.hide('governorates-modal')
          this.payload = this.resetObject(this.payload)
          this.getGovernorates()
        })
      } else {
        this.updateGovernorates(this.payload).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$bvModal.hide('governorates-modal')
          this.getGovernorates()
        })
      }
    },
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.payload = this.resetObject(this.payload)
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getGovernorates(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
      this.authUserPermissions();
      this.getGovernorates()
  }
}
</script>
